<template>
  <div>
    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summary-title-order-amount-payfast"
        data-testid="summary-title-order-amount-payfast"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.airWallex.labelOrderAmount') }}:
      </div>
      <strong
        id="summary-local-bank-order-amount"
        data-testid="summary-payfast-order-amount"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
    </div>

    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summany-local-bank-title-payment-method"
        data-testid="summany-local-bank-title-payment-method"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}:
      </div>

      <strong
        id="summary-local-bank-method"
        data-testid="summary-local-bank-method"
        class="font-14 text-primary"
      >
        <img
          :src="logoLocalBank"
          height="16px"
          alt="PayFast"
          style="margin-right: 5px"
        >
        Local bank
      </strong>
    </div>

    <div
      v-if="percentFee > 0"
      class="d-flex align-items-center flex-row justify-content-between mb-1"
    >
      <div
        id="summary-local-bank-title-service-fee"
        data-testid="summary-local-bank-title-service-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}:
        </small>
      </div>
      <strong
        id="summary-local-bank-current-service-charge"
        data-testid="summary-local-bank-current-service-charge"
        class="text-primary"
      >{{ localStringFormat(serviceCharge) }} USD</strong>
    </div>

    <div
      v-if="methodFee > 0"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="summary-title-airwallex-fee"
        data-testid="summary-title-airwallex-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', { payment: 'Local bank' }) }}:
      </div>
      <div>
        <div
          class="d-flex align-items-center"
        >
          <strong
            id="summary-current-airwallex-fee"
            data-testid="summary-current-airwallex-fee"
            class="text-primary"
          >{{ localStringFormat(localBankFee) }} USD</strong>
        </div>
      </div>
    </div>

    <div class="align-items-center d-flex flex-row justify-content-between mb-1">
      <div
        id="summary-airwallex-title-tax-fee"
        data-testid="summary-airwallex-title-tax-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}:
      </div>
      <strong
        id="summary-airwallex-current-tax-fee"
        data-testid="summary-airwallex-current-tax-fee"
        class="text-primary"
      >{{ localStringFormat(tax) }} USD</strong>
    </div>

    <hr class="hr-custom">

    <div class="d-flex align-items-center justify-content-between">
      <div class="font-14 text-placeholder text-left">
        {{
          `${$t('addFundPage.creditCardPayment.textTotalAmount')} = ${$t('balance.textAmount')} ${ serviceCharge > 0 ? `+ ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : ''} + ${$t('payment.balanceTransactionsTable.textTax')} ${ localBankFee > 0 ? `+ ${$t('payment.paypal.textPaymentFee', {payment:"Local bank"})}` : '' } ${hasAMId ? ` + ${$t('payment.balanceTransactionsTable.textTip')}` : ''}`
        }}
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-1 mt-2">
      <div class="font-24 text-placeholder">
        {{ $t('SMB.payment.totalAmount') }}
      </div>
      <strong
        v-if="amount"
        id="total-amount"
        class="font-24 total-amount"
      >
        {{ localStringFormat(totalAmount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="font-14 text-placeholder">
        {{ $t('SMB.payment.textAmountConvert') }}
      </div>
      <strong
        v-if="amount"
        id="amount"
      >
        {{ localStringFormat(convertUSDtoVND(totalAmount)) }} VNĐ
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin],

  data() {
    return {
      amount: Number(this.$route.query.amount) || 0,
      bankTransferAmount: this.$route.query.bankTransferAmount || '',
      paymentMethod: this.$route.query.paymentMethod || '',
    }
  },

  computed: {
    ...mapGettersAuth(['user', 'generalConfigs']),

    logoLocalBank() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-local-bank.png')
    },

    isInTrial() {
      return this.user?.isInTrial
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },

    taxRate() {
      return this.user?.data?.plans?.transferFee?.tax || 0
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.localBank || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.localBankGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    serviceCharge() {
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    tax() {
      return this.amountFormat(this.taxRate * this.amount)
        .toLocaleString('en-US')
    },

    localBankFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    totalAmount() {
      const tax = this.amountFormat(this.taxRate * this.amount)
      const serviceFee = this.amountFormat(this.percentFee * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      return (this.amount + tax + serviceFee + methodFee)
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
.transfer {
  max-width: 125px;
}

.total-amount{
  color: #00A9DE;
}
</style>
