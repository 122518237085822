<script>
export default {
  name: 'TimeLine',
  props: {
    stepActive: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      steps: [
        {
          title: this.$t('payment.pancake.timeLine.verifyEmail'),
          step: 1,
        },
        {
          title: this.$t('payment.pancake.timeLine.authTikTok'),
          step: 2,
        },
        {
          title: this.$t('payment.pancake.timeLine.addBalance'),
          step: 3,
        },
        {
          title: this.$t('payment.pancake.timeLine.addFund'),
          step: 4,
        },
      ],
    }
  },
  computed: {
    timelineItemWidth() {
      return 100 / this.steps.length
    },
  },
}
</script>

<template>
  <div class="timeline-container">
    <ul
      class="custom-timeline"
    >
      <li
        v-for="step in steps"
        :key="step.step"
        :class=" { 'active-tl': step.step <= stepActive }"
        :style="{ width: `${timelineItemWidth}%` }"
      >
        {{ step.title }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.timeline-container{
  width: 100%;
  height: 80px;
}

.custom-timeline{
  counter-reset: step 0;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

.custom-timeline li{
  list-style: none;
  float: left;
  position: relative;
  text-align: center;
  font-size: 14px;
}

.custom-timeline li:before{
  position: relative;
  counter-increment: step;
  content: counter(step);
  width: 32px;
  height: 32px;
  border: 3px solid white;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-weight: 700;
  line-height: 26px;
  margin: 0 auto 10px auto;
  background: #DBDBDB;
  color: #000;
  transition: all ease-in-out .3s;
  z-index: 20;
}

.custom-timeline li:after{
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #DBDBDB;
  top: 15px;
  left: -50%;
  transition: all ease-in-out .3s;
  z-index: 10;
}

.custom-timeline li:first-child:after{
  content: none;
}

.custom-timeline li.active-tl:before{
  content: url('../../src/assets/images/pages/payment/ic-tick.svg');
  line-height: 32px;
  background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
  color: #F1F1F1;
}

.custom-timeline li.active-tl + li:after{
  background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
}
</style>
