<template>
  <b-modal
    :visible="visible"
    size="first-top-up"
    body-class="first-top-up-success-modal"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <div class="text-center">
      <TimeLine :step-active="3" />

      <p class="font-weight-bold font-24 mb-1">
        {{ $t('payment.pancake.thankYouFirstPayment') }}
      </p>
      <b-img
        :width="182"
        :height="162"
        :src="require('@/assets/images/pages/payment/ic-tiktok-coin.png')"
        alt="TikTok logo"
      />
      <p class="font-14 mb-2">
        {{ $t('payment.pancake.transferMoneyPrompt') }}
        <a
          href="https://tiktok.ecomdymedia.com"
          target="_blank"
          style="color: #2667FF"
        >https://tiktok.ecomdymedia.com
        </a>, {{ $t('payment.pancake.loginPrompt') }}
      </p>

      <div class="wrapper-btn">
        <btn-loading
          class="btn-submit-outline font-14 custom-btn"
          variant-convert="btn-submit-outline"
          pill
          @click="handleNotThisTime"
        >
          {{ $t('payment.pancake.notThisTime') }}
        </btn-loading>

        <btn-loading
          class="custom-btn"
          variant-convert="btn-submit"
          pill
          @click="handleAddFund"
        >
          {{ $t('payment.pancake.addFund') }}
        </btn-loading>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BImg, BModal } from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import TimeLine from '@/components/TimeLine.vue'

export default {
  name: 'ConnectTikTokSuccessModal',
  components: {
    BtnLoading, BImg, BModal, TimeLine,
  },
  data() {
    return {
      visible: false,
    }
  },
  created() {
    const step = localStorage.getItem('pancakeUserStep')
    if (step) {
      this.visible = true
    }
  },
  methods: {
    closeModal() {
      this.visible = false
    },

    handleNotThisTime() {
      localStorage.removeItem('pancakeUserStep')
      this.navigateToPancake()
    },

    handleAddFund() {
      this.$router.push({
        path: '/payment',
      })
      this.closeModal()
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper-btn {
  display: flex;
  justify-content: space-evenly;

  .custom-btn {
    width: 180px;
    @media (max-width: 576px) {
      width: 130px;
    }
  }
}
</style>

<style lang="scss">
.modal-content {
    .first-top-up-success-modal {
      padding: 40px;
      @media (max-width: 576px) {
        padding: 20px;
        width: 100%;
      }
    }
}

.modal .modal-first-top-up {
  max-width: 620px;
  width: 620px;

  @media (max-width: 767px) {
    max-width: 95%;
    width: 100%;
  }
}
</style>
